<template>
	<!-- Error page-->
	<div class="misc-wrapper">
		<b-link class="brand-logo">
			<vuexy-logo />
			<h2 class="brand-text text-primary ml-1">高性能智能建筑系统</h2>
		</b-link>

		<div class="misc-inner p-2 p-sm-3">
			<div class="w-100 text-center">
				<h2 class="mb-1">页面未找到 🕵🏻‍♀️</h2>
				<p class="mb-2">很抱歉，未找到你想要访问的页面</p>

				<b-button :to="{ path: '/login' }" class="my-2 btn-sm-block" variant="primary"> 回到首页</b-button>
			</div>
			<!-- image -->
			<b-img :src="imgUrl" alt="Error page" fluid />
		</div>
	</div>
	<!-- / Error page-->
</template>

<script>
	/* eslint-disable global-require */
	import VuexyLogo from "@/@core/layouts/components/Logo.vue";
	import store from "@/store";

	export default {
		components: {
			VuexyLogo,
		},
		data() {
			return {
				downImg: require("@/assets/images/pages/error.svg"),
			};
		},
		computed: {
			imgUrl() {
				if (store.state.appConfig.layout.skin === "dark") {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.downImg = require("@/assets/images/pages/error-dark.svg");
					return this.downImg;
				}
				return this.downImg;
			},
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/pages/page-misc.scss";
</style>
